import React, { useEffect, useRef, useState } from 'react'
import Alert from '../shapes/Alert';
import LandingMenu from '../landing/LandingMenu';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import '../../css/workspace.css'
import '../../css/WorkspaceIconPanel.css'
import '../../css/legalInfo.css'
import WorkspaceInfoIcon from '../../assets/WorkspaceInfo.svg'
import CreditCard from '../../assets/CreditCard.svg'
import Users from '../../assets/Users.svg'
import WorkspaceIconPanel from './WorkspaceIconPanel';
import UserCircle from '../auth/UserCircle';
import PdfUploader from '../shapes/PdfUploader';
import axios from 'axios';
import PdfDownloader from '../shapes/PdfDownloader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import ModalComponent from '../shapes/ModalComponent';
import CancelRequest from '../../assets/CancelRequest.png'
import { count } from 'console';
import { useParams } from 'react-router-dom';
import { Workspace } from '../../types/Workspace';


const token = localStorage.getItem('token');
const BankAddress = () => {
  const { uniCode } = useParams();
  const pages = {
    'Workspace Info': [`/workspace-info/${uniCode}`, WorkspaceInfoIcon],
    'Collaborator': ['/collaborator/' + uniCode, Users],
    'Plan and Billing': ['', CreditCard],
  }
  const [showAlert, setShowAlert] = useState(false);
  const [alertBody, setAlertBody] = useState('');
  const [alertStatus, setAlertStatus] = useState('');
  const [loading, setLoading] = useState(false);

  const [country, setCountry] = useState('');
  const [city, setCity] = useState('');
  const [district, setDistrict] = useState('');
  const [streetName, setStreetName] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [selectedWorkspace, setSelectedWorkspace] = useState<Workspace>({
    id: -1,
    name: '',
    snippet: '',
    description: '',
    uniCode: '',
    image: '',
  });


  const fetchInitialData = async () => {
    setLoading(true);
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/get-workspace-address/${uniCode}`;
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const workspaceAddress = response.data.workspace_address;
      console.log(workspaceAddress);
      console.log(response.data.selectedWorkspace);
      setSelectedWorkspace(response.data.selectedWorkspace)
      if (workspaceAddress != null) {
        setCountry(workspaceAddress.country);
        setCity(workspaceAddress.city);
        setDistrict(workspaceAddress.district);
        setStreetName(workspaceAddress.street_name);
        setPostalCode(workspaceAddress.postal_code);
      }
    } catch (error) {
      console.error('Error fetching legal info:', error);
    } finally {
      setLoading(false);
    }
  }

  const handleSubmitBankAddress = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setLoading(true);
      const formData = new FormData(e.currentTarget);
      const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/save-workspace-address/${uniCode}`
      const response = await axios.post(url, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      // const legalInfo = response.data.legalInfo;
      // Object.keys(legalInfo).forEach((key) => {
      //   let data = legalInfo[key];
      //   console.log(key);
      //   console.log(data);
      //   let divContainer = document.querySelector(`div[data-pdf-name="${key}"]`) as HTMLElement;
      //   if (divContainer) {
      //     divContainer.setAttribute('data-pdf', data ? data.substring(data.indexOf('/') + 1) : '');
      //     if (data != null) {
      //       divContainer.querySelector('img')?.classList.remove('inactive');
      //     }
      //   }

      //   const checkCircles = document.querySelectorAll('.check-circle') as NodeListOf<HTMLElement>;
      //   checkCircles.forEach((checkCircle) => {
      //     if (checkCircle.classList.contains('text-success')) {
      //       console.clear();
      //       const inputShape = checkCircle.closest('.input-shape') as HTMLElement;
      //       const deletePDF = inputShape.querySelector('.delete-pdf') as HTMLElement;
      //       deletePDF.classList.remove('inactive');

      //     }
      //   });
      // })
      setShowAlert(true);
      setAlertBody('Address saved successfully');
      setAlertStatus('success');
    } catch (error) {
      console.error('Address was not saved:', error);
      setAlertBody('Address was not saved');
      setAlertStatus('error');
    } finally {
      setTimeout(() => {
        setShowAlert(false);
      }, 5000);
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchInitialData();
  }, []);

  return (
    <div className="main-container flex-container">
      <div>
        {showAlert ? <Alert body={alertBody} status={alertStatus} /> : ""}
      </div>
      <LandingMenu pages={pages} hidePopup={true} hidePanelContents={false} pageProp="WORKSPACE INFO" selectedWorkspace={selectedWorkspace} />
      <div id="workspace-info-side" className='main-side flex-10'>
        <div id="user-section" className="flex-container flex-space-between">
          <div id="title">
            <h3 className='bold-text'>Workspace info</h3>
            <p>Company Address</p>
          </div>
          <UserCircle />
        </div>

        <div id='content-container' className="">
          {
            <>
              <p className={`bold-text ${loading ? '' : 'overnight'}`} style={
                {
                  'color': '#94ddf3',
                  'display': 'block',
                  'fontSize': '2rem',
                  'position': 'absolute',
                  'marginBottom': '.5rem'
                }}>
                Loading...
              </p>
            </>
          }

          {
            uniCode
              ?
              <WorkspaceIconPanel page="workspace" uniCode={uniCode} />
              :
              ''
          }
          <div className="form-box rounded-radius w-50 m-t-5 pd-1 right-panel">
            <form id='workspace-form' method="post" onSubmit={handleSubmitBankAddress}>
              <input type="hidden" name="workspace_uni_code" value={uniCode} />
              <div className="flex-container vertical-flex form-container">
                <div className="flex-container w-100 bank-account-row">
                  <div className="input-shape w-100 flex-1-4">
                    COUNTRY
                  </div>
                  <div className="input-shape flex-3-4">
                    <input type="text" name="country" placeholder='Country...' required value={country ? country : "Saudi Arabia - KSA"} onChange={(e) => setCountry(e.target.value)} />
                  </div>
                </div>
                <div className="flex-container w-100 bank-account-row">
                  <div className="input-shape w-100 flex-1-4">
                    City
                  </div>
                  <div className="input-shape flex-3-4">
                    <input type="text" name="city" placeholder='City...' required value={city} onChange={(e) => setCity(e.target.value)} />
                  </div>
                </div>
                <div className="flex-container w-100 bank-account-row">
                  <div className="input-shape w-100 flex-1-4">
                    District
                  </div>
                  <div className="input-shape flex-3-4">
                    <input type="text" name="district" placeholder='District...' required value={district} onChange={(e) => setDistrict(e.target.value)} />
                  </div>
                </div>
                <div className="flex-container w-100 bank-account-row">
                  <div className="input-shape w-100 flex-1-4">
                    Street Name
                  </div>
                  <div className="input-shape flex-3-4">
                    <input type="text" name="street_name" placeholder='Street Name...' required value={streetName} onChange={(e) => setStreetName(e.target.value)} />
                  </div>
                </div>
                <div className="flex-container w-100 bank-account-row">
                  <div className="input-shape w-100 flex-1-4">
                    Postal Code
                  </div>
                  <div className="input-shape flex-3-4">
                    <input type="text" name="postal_code" placeholder='Postal Code...' required value={postalCode} onChange={(e) => setPostalCode(e.target.value)} />
                  </div>
                </div>
              </div>
              <input type="submit" value="Save" className='btn btn-dark m-t-1 sejel-button' />
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BankAddress