import React from 'react'
import './../css/workspace.css'
import LandingMenu from './landing/LandingMenu'
import LandingSection from './landing/LandingSection'
import companies from './../assets/companies.svg'
import dataSource from './../assets/data-source.svg'
import { RootState } from '../store'
import { useSelector } from 'react-redux'

const Companies = () => {
  const pages = {
    'Companies': ['', companies],
    'Data Source': ['', dataSource],
  }
  const selectedWorkspace = useSelector((state: RootState) => state.workspaceState.selectedWorkspace);
  return (
    <div className="main-container flex-container">
      <LandingMenu pages={pages} hidePopup={false} hidePanelContents={false} pageProp='companies' selectedWorkspace={selectedWorkspace} />
      <LandingSection />

    </div>
  )
}

export default Companies