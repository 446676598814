import React from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from './../../reducers/reducers';

const LeanConnect: React.FC = () => {
    const user = useSelector((state: RootState) => state.userState);
    const link = async () => {
        try {
            const token = localStorage.getItem('token');
            
            // Get the customer access token from backend
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/lean/customer-token`, {
            // const response = await axios.get(`${process.env.REACT_APP_API_URL}/lean/customer-token`, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                }
            });
            console.log(response.data)
            const customerToken = response.data.customer_token;
            const leanCustomerId = response.data.lean_customer_id;
            const leanAppToken = response.data.lean_app_token;

            console.log(customerToken);
            console.log(leanCustomerId);

            if (window.Lean) {
                // window.Lean.link({
                //     app_token: customerToken,
                //     customer_id: leanCustomerId,
                //     permissions: ['identity', 'transactions', 'balance', 'accounts'],
                //     sandbox: true,
                // });
                window.Lean.connect({
                    app_token: leanAppToken,
                    permissions: ["identity","accounts","balance","transactions"],
                    customer_id: leanCustomerId,
                    sandbox: true,
                    access_token: customerToken,
                    fail_redirect_url: 'https://sejel.io/lean/failure',
                    success_redirect_url: 'https://sejel.io/lean/success',
                    account_type: 'personal',
                  })
                  
            } else {
                console.error('Lean SDK is not loaded.');
            }
        } catch (error) {
            console.error('Error initializing Lean SDK:', error);
        }
    };

    return (
        <div>
            <button onClick={link}>Connect Bank Account</button>
        </div>
    );
};

export default LeanConnect;
