import React from 'react';

const LeanSuccess: React.FC = () => {
  return (
    <div>
      <h2>Bank Account Connected Successfully!</h2>
    </div>
  );
};

export default LeanSuccess;
